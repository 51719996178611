* {
  margin: 0px;
  padding: 0px;
}

html {
  scroll-behavior: smooth;
  background-color: #11113e;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #bdbfde;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navigation {
  background-color: #000629;
  color: #bdbfde;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  overflow: auto;
  transition: left 0.2s ease-in-out;
}

.nav-element {
  display: block;
  text-decoration: none;
  color:#c6c5db;
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.nav-element:hover,
.nav-element:active,
.nav-element:focus,
.nav-element:visited {
    background-color: #000629;
    color: #bdbfde;
}

.nav-element span {
  font-size: 32px;
  padding-bottom: 2vh;
  display: block;
}

#content {
  background-color: #000629;
  color: white;
  margin-left: 250px;
  text-align: left;
  overflow-x: hidden;
}

.section {
  min-height: 100vh;
}

.section-data {
  padding: 3%;
  width: calc(94% - 250px);
}

.section-data h2 {
  margin-bottom: 3%;
  font-size: 38px;
  color: #bdbfde;
}

div#intro {
  background: radial-gradient(circle at 100% 0%,#0000 0%,var(--color-v) 71%),linear-gradient(to right,var(--color),var(--color)),var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode: var(--blend-top, normal), var(--blend-bottom, saturation), normal;
  --image: url(images/intro-background.jpg);
  --image2: url(images/intro-background.jpg);
  --color-v: #000629;
  --color: grey;
  flex: 1;
  width: 100vw;
}

div#about {
  background: radial-gradient(circle at 100% 0%,#0000 0%,var(--color-v) 71%),linear-gradient(to right,var(--color),var(--color)),var(--image2);
  background-position: 70%;
  background-size: cover;
  background-blend-mode: var(--blend-top, normal), var(--blend-bottom, saturation), normal;
  --image: url(images/aboutme-background.jpg);
  --image2: url(images/aboutme-background.jpg);
  --color-v: #000629;
  --color: grey;
  flex: 1;
  width: 100vw;
}

#resumeLink {
  margin-top: 20px;
}

#resumeLink a {
  color: #bdbfde;
  text-decoration: none;
  font-style: italic;
}

#resumeLink svg {
  padding-right: 10px;
}

#resumeLink a:hover,
#resumeLink a:active,
#resumeLink a:focus,
#resumeLink a:visited {
  color: #bdbfde;
}


ul {
  list-style-type: none;
}

.experience-element ul {
  margin: 4% 0 4% 4%;
}

.experience-element li {
  list-style-type: initial;
  margin-bottom: 17px;
}

#skills-data li {
  margin-left: 4%;
  margin-bottom: 18px;
}

li h3 {
  font-weight: 800;
  font-style: italic;
}

.section-content {
  max-width: 700px;
}

.section-content p {
    margin-bottom: 20px;
}

.warning {
  padding: 21px;
  background-color: #c0c0c059;
  margin-bottom: 20px;
}

#mobileheader {
  display: none;
}

#phoneNote {
  color: #686b90;
}

@media (max-width: 600px) {
  #mobileheader {
    display: block;
    background-color: #000629;
    color: #bdbfde;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px 0px 10px 20px;
    text-align: left;
    font-size: 2em;
    height: 36px;
  }

  #navigation {
    left: -251px;
    top: 56px;
    border-right: 1px solid #353642;
  }

  #navigation.active {
    left: 0px;
  }

  #content {
    margin-left: 0px;
  }

  .section {
    padding-top: 58px;
    min-height: 80vh;
  }

  .section:last-child {
    min-height: 100vh;
  }

  .section-content {
    max-width: 100%;
  }

  .section-data {
    padding: 3%;
    width: 90%;
  }
}